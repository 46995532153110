<template>
  <div class="favorites">
    <div class="menu">
      <div class="radio">
        <label for="rad1" class="container">
          <input id='rad1' class="radio_one" type="radio" checked name="radio">
          <span class="checkmark">One</span>
        </label>
        <label for="rad2" class="container">
          <input id='rad2' class="radio_two" type="radio" name="radio">
          <span class="checkmark">Two</span>
        </label>
      </div>
      <div class="content">
        <div id="content_one" class="content_one">
          111111111
        </div>
        <label id="content_two" class="content_two">
          22222222
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.favorites{
  position: absolute;
  top: 64px;
  bottom: 72px;
  right: 0px;
  left: 0px;
}
.menu{
  margin: 20px; 
  width: 1200px;
  height: 505px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 1px 0px 5px #000;
  display: flex;
}
.radio{
  border-right: 1px solid #ccc;
  width: fit-content;
}
.content{
  flex: 1;
}
#content_one,#content_two{
  display: none;
}
.container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 207px;
  width: 200px;
  background-color: #fff;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px 0px 0px 10px;
}
.container:hover input ~ .checkmark {
  background-color: #7878780D;
}
.container .radio_one:checked ~ .checkmark, .radio_two:checked ~ .checkmark {
  background-color: #099F450D;
}
#rad1:checked ~ .content_one {
  display: block;
}
#rad2:checked ~ .content_two {
  display: block;
}
</style>